import React from 'react'
import s from './footer.module.scss'
import { IconGO8 } from 'components/icons/Icons'

const Footer = () => {
  // render

  return (
    <div>
      <footer className={s.footer}>
        <IconGO8></IconGO8>
        <p>
          Copyright © 2020 Monash University. ABN 12 377 614 012 Accessibility -
          Disclaimer and copyright - Website terms and conditions - Data
          Protection and Privacy Procedure - Data Consent Settings, Monash
          University CRICOS Provider Number: 00008C, Monash College CRICOS
          Provider Number: 01857J. Monash University is a registered higher
          education provider under the TEQSA Act 2011.
        </p>
      </footer>
    </div>
  )
}

export default Footer
