const getCorsUrl = (urlString, corsResolverOrigin, addPath) => {
  const oldOrigin = new URL(urlString).origin
  return corsResolverOrigin + addPath + urlString.substring(oldOrigin.length)
}

const resolveCors = (oktaAuth, corsResolverOrigin, addPath) => {
  if (!addPath) addPath = '/sso/okta'
  if (!oktaAuth.options && oktaAuth._oktaAuth) {
    oktaAuth = oktaAuth._oktaAuth
  }
  if (!corsResolverOrigin) {
    if (window.location.origin.startsWith('http://localhost')) {
      corsResolverOrigin = 'http://localhost:8080'
    } else {
      corsResolverOrigin = window.location.origin
    }
  }
  const httpRequestClient = oktaAuth.options.httpRequestClient
  oktaAuth.options.url = getCorsUrl(
    oktaAuth.options.url,
    corsResolverOrigin,
    addPath
  )
  const issuer = oktaAuth.options.issuer
  oktaAuth.options.httpRequestClient = (method, url, args) => {
    if (!url.startsWith(window.location.origin)) {
      args.withCredentials = false // for localhost and shared proxy
    }
    if (url.startsWith(issuer)) {
      url = getCorsUrl(url, corsResolverOrigin, addPath)
    }
    if (url.endsWith("/api/v1/sessions/me") && method === "DELETE") {
      console.log("Closing session is not allowed.")
      return Promise.resolve({})
    }
    return httpRequestClient(method, url, args)
  }
}

module.exports = {
  resolve: resolveCors
}
