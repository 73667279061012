import React from 'react'
import './App.scss'
import Login from 'Login'
import withAuth from '@okta/okta-react/dist/withAuth'
// import { useAuth } from 'hooks/useAuth'
import Banner from 'components/banner/Banner'
import Units from 'components/units/Units'
import screenshot from 'assets/ss.png'

const App = withAuth(({ auth }) => {
  // const [authenticated, user] = useAuth(auth)

  return (
    <div className='App'>
      <Login></Login>
      <Banner></Banner>
      <Units></Units>

      <img className='ss' src={screenshot} alt='screenshot'></img>

      {/* {authenticated !== null && (
        <button
          onClick={() => (authenticated ? auth.logout() : auth.login())}
          className='App-link'>
          Log {authenticated ? 'out' : 'in'}
        </button>
      )} */}
    </div>
  )
})

export default App
