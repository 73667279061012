import React from 'react'
import s from './banner.module.scss'
import Welcome from 'components/welcome/Welcome'
import Nav from 'components/nav/Nav'

function Banner() {
  // render

  return (
    <div className={s.banner}>
      <Nav></Nav>
      <Welcome></Welcome>
    </div>
  )
}

export default Banner
