import React, { Component } from "react"
import { withAuth } from "@okta/okta-react"

export default withAuth(
  class Login extends Component {
    constructor(props) {
      super(props)
      this.state = { authenticated: null }
      this.checkAuthentication = this.checkAuthentication.bind(this)
      this.checkAuthentication()
      this.login = this.login.bind(this)
      this.logout = this.logout.bind(this)
    }

    async checkAuthentication() {
      const authenticated = await this.props.auth.isAuthenticated() // This can be used to check if the user is authenticated.
      if (authenticated !== this.state.authenticated) {
        this.setState({ authenticated })
      }
    }

    componentDidUpdate() {
      this.checkAuthentication()
    }

    async login() {
      // Redirect to '/' or other path after login
      this.props.auth.login("/")
    }

    async logout() {
      // Just logout. DONOT use this function to redirect.
      await this.props.auth.logout()
      window.location.href = "/"; // Force reload the page or redirect here.
    }

    render() {
      if (this.state.authenticated === null) return null
      return this.state.authenticated ? (
        <button onClick={this.logout}>Logout</button>
      ) : (
        <button onClick={this.login}>Login</button>
      )
    }
  }
)