import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Security, ImplicitCallback, Auth } from '@okta/okta-react'
import './index.scss'
import App from './App.jsx'
import * as serviceWorker from './serviceWorker'
import Footer from 'components/footer/Footer'
import CorsResolver from 'utils/okta-cors-resolver.js'

const oktaConfig = {
  issuer: `${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/default`,
  redirect_uri: window.location.origin + '/implicit/callback',
  client_id: process.env.REACT_APP_OKTA_CLIENT_ID,
  pkce: true, // Added in okta-react 1.2.3
  history: []
}

const oktaAuth = new Auth(oktaConfig)
CorsResolver.resolve(oktaAuth)

ReactDOM.render(
  <Router>
    <Security auth={oktaAuth}>
      <Route path='/' exact component={App} />
      <Route path='/implicit/callback' component={ImplicitCallback} />
    </Security>
  </Router>,
  document.getElementById('root')
)
ReactDOM.render(<Footer />, document.getElementById('footer'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
