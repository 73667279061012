import React from 'react'
import s from './welcome.module.scss'

const Welcome = () => {
  // render

  return (
    <div className={s.welcome}>
      <h1>
        Hi Ming,
        <br />
        We can't wait to see you!
      </h1>

      <p>
        For students remaining in China, Monash wants to make sure you can get
        started with your 2020 studies.
      </p>
      <p>
        Until you are able to get to Australia, the start of your semester will
        be undertaken flexibly.
      </p>
      <p>
        The quality of all our Monash programs will not be compromised. Your
        lecturers will help you be completely on track while studying remotely.
      </p>
    </div>
  )
}

export default Welcome
