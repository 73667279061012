import React from 'react'
import { IconMonashLogo } from 'components/icons/Icons'
import s from './nav.module.scss'

const Nav = () => {
  // render

  return (
    <nav className={s.nav}>
      <div className={s.navContainer}>
        <IconMonashLogo></IconMonashLogo>
        <span>Personal Study Plan</span>
      </div>
    </nav>
  )
}

export default Nav
