import React, { useState } from 'react'
import s from './units.module.scss'
import UnitItem from './UnitItem'

const Units = () => {
  const [confirmation, setConfirmation] = useState(false)
  const [confirmationBox, setConfirmationBox] = useState(false)

  const list = [
    {
      code: 'MAE4404',
      name: 'Aerospace practices',
      flex: true
    },
    {
      code: 'MAE4980',
      name: 'Aircraft engines',
      flex: true
    },
    {
      code: 'MEC4446',
      name: 'Composite structures',
      flex: true
    },
    {
      code: 'MEC4447',
      name: 'Computers in fluids and energy',
      flex: true
    }
  ]

  const unitList = list.map((item, i) => {
    return <UnitItem key={i} {...{ item }}></UnitItem>
  })

  const handleClick = val => {
    setConfirmation(val)
    setConfirmationBox(true)
  }

  // render

  return (
    <div className={s.unitsContainer}>
      <div className={s.units}>
        <div className={s.unitsHeader}>
          <h2>Starting your semester while you’re still overseas</h2>
        </div>
        <ul className={s.unitList}>{unitList}</ul>
      </div>

      <div className={s.acceptContainer}>
        <h4>I want online access for the start of my studies</h4>
        <button className={s.acceptButton}>Confirm</button>
        <button className={s.discuss}>I want to talk to someone</button>
      </div>

      <div className={s.confirmationModal}></div>
    </div>
  )
}

export default Units
