import React from 'react'
import s from './units.module.scss'

const UnitItem = ({ item }) => {
  const url = `https://handbook.monash.edu/2020/units/${item.code}`

  // render

  return (
    <li className={s.unitItem}>
      <a href={url} target='_blank' rel='noopener noreferrer'>
        <h3>
          <span className={s.code}>{item.code}</span>
          {item.flex && <span className={s.flexTag}>Flexible</span>}
        </h3>
        <p className={s.name}>{item.name}</p>
        {item.flex && (
          <p className={s.flexText}>
            Until you arrive, you can access this unit from your location for
            the start of your studies
          </p>
        )}
      </a>
    </li>
  )
}

export default UnitItem
